/*
Template Name: Meghna Responsive HTML5/CSS3 Business Template
Author: Themefisher
Author URI: www.Themefisher.com
Description: Meghna Responsive HTML5/CSS3 Portfolio Template
Version: 1.0
Tags: one-page, single-page, portfolio, custom-colors, post-formats, responsive, html5, css3
*/
/*=== MEDIA QUERY ===*/
@import url("https://fonts.googleapis.com/css?family=Anaheim|Quattrocento+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:300,400,600");
html {
  background-color: #ffffff;
}

body {
  background-color: #ffffff;
  font-family: "Anaheim", sans-serif;
  color: #afbac4;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: "Quattrocento Sans", sans-serif;
  color: #d5dbe1;
}

p {
  font-family: "Quattrocento Sans", sans-serif;
}

/*=================================================================
  Basic Setup
==================================================================*/
::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #2b6cd5;
}

::selection {
  color: #fff;
  background: #2b6cd5;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  color: #fff;
  transition: all 0.3s ease-in 0s;
}

iframe {
  border: 0;
}


a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
  color: #2b6cd5;
}

a:focus,
a:hover {
  color: #6cb670;
}

.kill-margin-bottom {
  margin-bottom: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.btn {
  background-color: transparent;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0;
  transition: all 0.3s ease-in 0s;
}

.btn:focus {
  color: #ddd;
}

.btn-transparent {
  border: 1px solid #4e595f;
}

.btn-transparent:hover,
.btn-transparent:focus {
  background-color: #2b6cd5;
  border: 1px solid transparent;
  color: #fff;
}

.form-control {
  background-color: #2f383d;
  border-radius: 0;
  padding: 5px 10px;
  border: 0 none;
  color: rgb(24, 23, 23);
}

.form-control:focus {
  box-shadow: 0 0 0;
}

.form-control::-webkit-input-placeholder {
  /*  Chrome, Safari, Opera  */
  color: #ddd;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #ddd;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #ddd;
}

.form-control:-ms-input-placeholder {
  /*  Internet Explorer  */
  color: #ddd;
}

#loading-mask {
  background: #333439;
  height: 100%;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.loading-img {
  height: 100%;
  left: 0;
  position: fixed;
  top: 50%;
  width: 100%;
}

.border {
  border-top: 2px solid rgba(236, 239, 241, 0.07);
  height: 1px;
  margin: 15px auto 0;
  position: relative;
  width: 30%;
}
.borderLight {
  border-top: 2px solid rgba(0, 25, 48, 0.07);
  height: 1px;
  margin: 15px auto 0;
  position: relative;
  width: 30%;
}

.border:before {
  background-color: #fff;
  content: "";
  height: 6px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  top: -4px;
  width: 50px;
  background-color: #2b6cd5;
}
.borderLight:before {
  background-color: #fff;
  content: "";
  height: 6px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  top: -4px;
  width: 50px;
  background-color: #2b6cd5;
}

.color {
  color: #6cb670;
}

.sub-title {
  padding: 0 0 50px;
}

.sub-title > h3 {
  border-left: 3px solid #ddd;
  border-right: 3px solid #ddd;
  display: inline-block;
  padding: 5px 25px;
  text-transform: uppercase;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.bg-one {
  background-color: #fff;
  padding-top: 100px;
  padding-bottom: 100px;

}

.bg-dark {
    background-image: linear-gradient(to right, #274472, #001930);
}

.parallax-section {
  background-attachment: fixed !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.no-padding {
  padding: 0 !important;
}

.inline-block {
  display: inline-block;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  transition: all 0.2s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus,
a:hover {
  color: #2b6cd5;
}

.btn-main {
  background: #00192f;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 35px;
  text-transform: uppercase;
}

.btn-main:hover {
  background: #2c9091;
  color: #fff;
}

.mt-20 {
  margin-top: 20px;
}

.section {
  padding: 100px 0;
}

@media (max-width: 480px) {
  .section {
    padding: 50px 0;
  }
}

@media (max-width: 768px) {
  .section {
    padding: 50px 0;
  }
  .newSection {
    padding: 20px;
  }
}

.section-sm {
  padding: 70px 0;
}

.section-xs {
  padding: 50px 0;
}

.btn:focus {
  color: #ddd;
}

#home {
  position: relative;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.parallax-section {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#preloader {
  background: #fff;
  height: 100%;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.preloader {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #2b6cd5;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.title {
  padding-bottom: 60px;
}

.title h2 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 38px;
  color: #fff;
}
.darkTitle {
  color: #274472;
}
.lightTitle {
  color: #4F87E1;
}
/* .title h2 span {
  color: #2b6cd5;
} */

.title p {
  color: #666;
}

.title.title-white h2 {
  color: #fff;
}

.bg-gray {
  background: #f9f9f9;
}

.section-bg {
  background: #fff;
}

.overly {
  position: relative;
  background-image: linear-gradient(to right, #274472, #001930);
}

.overly h2 {
color: #ffffffd2;
  }
  .overly p {
    color: #ffffff9d;
      }
  

/* .overly:before {
  content: "";
    background-image: linear-gradient(to right, #274472, #001930);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
} */
.overly2:before {
  content: "";
  background: rgb(255, 255, 255);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.header-bradcrumb {
  background: transparent;
}

.header-bradcrumb a,
.header-bradcrumb .active {
  color: #cfcfcf;
  font-weight: 200;
  font-family: "Quattrocento Sans", sans-serif;
}

.header-bradcrumb a:hover {
  color: #2b6cd5;
}

.slick-slide {
  outline: none;
}

/* CSS mfp-with-zoom class */
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.logo {
  margin-top: 10px;
  display: inline-block;
}

.single-page-header {
  /* background-image: url("../images/about/about-header.jpg"); */
  background-size: cover;
  padding: 140px 0 70px;
  text-align: center;
  color: #fff;
  position: relative;
}

.single-page-header:before {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.emergency {
  background-color: rgb(209, 48, 48);
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 9999;
  margin: 5px 5px 2px 5px;
  color: white;
  font-size: 20px;
}
.hideOnDesktop {
  display: none;
}



.bg-1 {
  /* background-image: url("../images/backgrounds/bg-1.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-2 {
  /* background-image: url("../images/backgrounds/bg-2.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.hero-area {
  /* background-image: url("../images/slider/hero-area2.jpg"); */
  /* background:linear-gradient(0deg, #01162abc, #001930bc), url("../images/slider/hero-area2.jpg"); */

  background-size: cover;
  height: 100vh;
  /* position: absolute; */
  top: 0;
  /* padding-top: 150px; */
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

/* .hero-area:before { */
  /* content: "";
  background-image: linear-gradient(#232b2f86, rgba(0, 0, 0, 0.805));
  background-blend-mode: overlay; */

  /* background: rgba(0, 25, 48, .63); */
  /* position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */

  /* -webkit-animation: zoomin 30s ease-in infinite;
  animation: zoomin 30s ease-in infinite;
  transition: all .5s ease-in-out;
  overflow: hidden; */
/* } */

/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
} /*End of Zoom in Keyframes */


.hero-area .block {
  /* text-align: center; */
  z-index: 99;
  margin-top: -350px;
  /* background: red; */
  width: 60%;
  text-align: left;
}


.hero-area .block2 {
  text-align: center;
  z-index: 99;
  margin-top: -200px;
  /* background: rebeccapurple; */
  width: 40%;
  padding: 0px 120px 0px 120px;
}

.scheduleBlock {
  background: #ffffff12;
   padding: 15px
}

@media screen and (max-width: 900px) {

  .hero-area {
    height: 80vh
  }

  .hero-area .block {
    width: 100%;
    display: none;
  }
  .hero-area .block2 {
    width: 100%;
    padding: 0px;

    /* display: none; */
  }
  .scheduleBlock {
    background-color: transparent;
  }
}
.hero-area .block .video-button {
  background: #fff;
  color: #2b6cd5;
  display: inline-block;
  height: 60px;
  width: 60px;
  border-radius: 50px;
}

.hero-area .block h1 {
  font-size: 50px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .hero-area .block h1 {
    font-size: 60px;
  }
}

.hero-area .block2 h1 {
  font-size: 40px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .hero-area .block2 h1 {
    font-size: 20px;
  }
}

.hero-area .block .mySlides .textSlides {
  padding-right: 20px;
}

.hero-area .block p {
  color: #fff;
  font-size: 20px;
  padding-right: 20px;
  /* width: 70%; */
  margin: 0 auto;
}

.hero-area .block .btn-transparent {
  margin-top: 40px;
  border-color: #fff;
  padding: 14px 50px;
  font-size: 18px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
}
.mySlides {
  animation-duration: 3s;
  animation-name: slidein;
  /* animation-delay: 10s; */
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 100%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.hero-area-video {
  height: 100vh;
  position: inherit !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-area-video .block {
  color: #fff;
  text-align: center;
}

.hero-area-video .block h1 {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-area-video .block p {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.hero-area-video .block .btn-main {
  margin-top: 20px;
}

.dark-bg {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

/*=================================================================
  About us section
==================================================================*/
.about .block {
    background-image: linear-gradient( #274472, #001930);
  padding: 30px;
  border-bottom: 2px solid transparent;
  transition: 0.5s all;
  margin-bottom: 20px;
  height: 500px;
  border-bottom: 15px solid #3e83f1;

}

.about .block:hover {
    background-image: linear-gradient( #274472, #001930, #001930, #2b6cd5);

  border-bottom: 2px solid #2b6cd5;
}

.about .block:hover .icon-box {
    /* border-color: #2b6cd5; */
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.about .block .icon-box {
  position: relative;
  width: 160px;
  height: 92.38px;
  background-color: transparent;
  margin: 46.19px auto 60px;
  padding: 20px 0;
  border-left: 2px solid rgba(236, 239, 241, 0.07);
  border-right: 2px solid rgba(236, 239, 241, 0.07);
  font-size: 40px;
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.about .block .icon-box:after,
.about .block .icon-box:before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 113.14px;
  height: 113.14px;
  background-color: inherit;
  left: 20.4315px;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
}

.about .block .icon-box:before {
  top: -56.5685px;
  border-top: 2px solid rgba(236, 239, 241, 0.07);
  border-right: 2px solid rgba(236, 239, 241, 0.07);
}

.about .block .icon-box:after {
  bottom: -56.5685px;
  border-bottom: 2px solid rgba(236, 239, 241, 0.07);
  border-left: 2px solid rgba(236, 239, 241, 0.07);
}
/* .about .block:hover .icon-box:before {
    border-color: #2b6cd5;

}
.about .block:hover .icon-box:after {
    border-color: #2b6cd5;

} */
.about .block .icon-box h3 {
  color: #d5dbe1;
}

/*=================================================================
  About us 2 section
==================================================================*/
.about-2 .content-block {
  padding: 40px;
}

.about-2 .content-block .media {
  margin-top: 25px;
}

.about-2 .content-block .media .pull-left {
  padding-right: 15px;
}

.about-2 .content-block .media i {
  font-size: 30px;
  color: #2b6cd5;
}

.about-2 .content-block .media h4 {
  font-size: 16px;
  font-weight: 600;
}

/*=================================================================
 Call To Action 1
==================================================================*/
.call-to-action {
  background-attachment: fixed;
  background-image: linear-gradient(#57a7cc, rgb(87, 203, 204));
}

.call-to-action h2 {
  font-weight: 700;
  color: #444;
}

.call-to-action p {
  font-size: 16px;
  color: #666;
}

.call-to-action .btn-main {
  margin-top: 20px;
}

/*=================================================================
 Call To Action 2
==================================================================*/
.call-to-action-2 {
  padding: 100px 0;
  /* background-image: url("../images/call-to-action/call-to-action-bg.jpg"); */
  background-size: cover;
  color: #fff;
}

.call-to-action-2 h2 {
  line-height: 1.5;
}

.call-to-action-2 p {
  color: #fff;
}

.call-to-action-2 .btn-main {
  margin-top: 20px;
}

.contact-us {
  padding-top: 100px;
}

.contact-form {
  margin-bottom: 60px;
}

.contact-form .form-control {
  background-color: transparent;
  border: 1px solid #27447225;
  height: 45px;
}

.contact-form textarea.form-control {
  padding: 10px;
  height: 120px;
}

.contact-form input:hover,
.contact-form textarea:hover,
#contact-submit:hover {
  border-color: #2b6cd5;
}

#contact-submit {
  border: 1px solid rgba(236, 239, 241, 0.07);
  background: #2b6cd5;
  padding: 12px 0;
  width: 100%;
  margin: 0;
}

.google-map #map-canvas {
  height: 400px;
}

.contact-info p {
  margin-bottom: 25px;
  color: #575757;
}

.contact-info h3 {
  color: #274472;
  font-weight: bold;
}


.con-info {
  margin-bottom: 10px;
}

.con-info i,
.con-info span {
  float: left;
}

.con-info span {
  margin: -5px 0 0 15px;
}

.error {
  display: none;
  padding: 10px;
  color: #d8000c;
  border-radius: 4px;
  font-size: 13px;
  background-color: #ffbaba;
}

.success {
  background-color: #6cb670;
  border-radius: 4px;
  color: #fff;
  display: none;
  font-size: 13px;
  padding: 10px;
}

#map {
  height: 370px;
  width: 100%;
}

/*=================================================================
  Item counter section
==================================================================*/
.counters-item {
  padding: 25px 0;
  margin-bottom: 10px;
  background: #292f36;
}

.counters-item i {
  margin-bottom: 10px;
  font-size: 40px;
  display: inline-block;
}

.counters-item span {
  display: block;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
}

.counters-item h3 {
  font-size: 20px;
  margin: 0;
}

/*=================================================================
  Pricing section
==================================================================*/
.price-item {
  border: 1px solid rgba(236, 239, 241, 0.07);
  padding-bottom: 30px;
  margin-bottom: 15px;
}

.price-title {
  background-color: #292f36;
  padding: 30px 0 20px;
}

.price-title h3 {
  color: #2b6cd5;
  margin: 0 0 10px;
  text-transform: uppercase;
}

.price-title p {
  font-size: 18px;
  font-weight: 400;
}

.price-title .value {
  color: #2b6cd5;
  font-size: 30px;
}

.pricing li {
  padding: 10px 0;
}

.pricing li:last-child {
  border-bottom: 1px solid rgba(236, 239, 241, 0.07);
  margin-bottom: 20px;
}

.pricing li:nth-child(2n) {
  background-color: #292f36;
}

.pricing li .btn {
  text-transform: uppercase;
  margin: 20px 0;
}

/*=================================================================
  Portfolio section
==================================================================*/
.portfolio-filter {
  margin-bottom: 40px;
  text-align: center;
}

.portfolio-filter button {
  border: none;
  outline: none;
  border: 1px solid rgba(236, 239, 241, 0.07);
  background: transparent;
  display: inline-block;
  font-size: 16px;
  padding: 6px 20px;
  font-weight: 700;
  color: #afbac4;
  transition: 0.3s all;
}

.portfolio-filter button.mixitup-control-active {
  border: 1px solid #2b6cd5;
  color: #2b6cd5;
}

.portfolio-items-wrapper .mix {
  padding: 5px;
}

.portfolio-block {
  position: relative;
}

.portfolio-block:before {
  transition: all 0.3s ease-in-out;
  background: white;
  opacity: 0;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  position: absolute;
  content: "";
}

.portfolio-block img {
  width: 100%;
  height: auto;
}

.portfolio-block:hover:before {
  opacity: 1;
}

.portfolio-block:hover .caption h4 {
  top: 45%;
}

.portfolio-block:hover .caption {
  opacity: 1;
  top: 55%;
}

.portfolio-block .caption {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.portfolio-block .caption h4 {
  font-size: 16px;
  font-weight: 600;
}

.portfolio-block .caption h4 a {
  color: #555;
}

.portfolio-block .caption .search-icon {
  background: #2b6cd5;
  width: 50px;
  height: 50px;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  border-radius: 30px;
}

.portfolio-block .caption .search-icon i {
  line-height: 50px;
}

/*=================================================================
  Services section
==================================================================*/
.service-block {
  color: #274472;
  padding: 60px 20px;
  border-left: 1px solid rgba(236, 239, 241, 0.07);
  border-top: 1px solid rgba(236, 239, 241, 0.07);
  margin-bottom: 40px;
  box-shadow: 2px 2px 5px rgba(44, 44, 44, 0.3), 13px 13px 0 rgb(0, 25, 47);
}
.service-block h3 {
color: #274472
}

.service-block .service-icon {
  margin-bottom: 10px;
  font-size: 50px;
}

/*=================================================================
  Services section 2
==================================================================*/
.service-2 .service-item {
  border: 1px solid #eee;
  margin-bottom: 30px;
  padding: 50px 20px;
  transition: all 0.3s ease 0s;
}

.service-2 .service-item:hover {
  box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
}

.service-2 .service-item:hover i {
  background: #fff;
  color: #2b6cd5;
}

.service-2 .service-item i {
  font-size: 30px;
  display: inline-block;
  background: #2b6cd5 none repeat scroll 0 0;
  border-radius: 30px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  height: 55px;
  line-height: 55px;
  margin-bottom: 20px;
  width: 55px;
  transition: all 0.3s ease 0s;
}

/*=================================================================
  Our skills
==================================================================*/
.skill-bar p {
  font-size: 18px;
  margin-bottom: 0;
}

.skill-bar p span {
  font-style: italic;
  font-size: 30px;
  color: rgba(187, 187, 187, 0.34);
  display: inline-block;
  vertical-align: middle;
}

.skill-bar .progress {
  height: 6px;
  border-radius: 0;
}

.skill-bar .progress .progress-bar {
  background: #2b6cd5;
}

/*=================================================================
  Our Team
==================================================================*/
.team-member {
  background-color: #292f36;
  margin-bottom: 10px;
}

.team-member:hover .mask {
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: rotateY(0deg) scale(1, 1);
  transform: rotateY(0deg) scale(1, 1);
}

.member-photo {
  overflow: hidden;
  position: relative;
}

@media (max-width: 768px) {
  .member-photo {
    text-align: center;
  }
}

.member-photo:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.member-photo img {
  transition: all 0.4s ease 0s;
}

@media (max-width: 768px) {
  .member-photo img {
    display: inline-block;
  }
}

.member-photo .mask {
  background-color: rgba(87, 203, 204, 0.7);
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.5s ease-out 0s;
  -webkit-transform: rotateY(180deg) scale(0.5, 0.5);
  transform: rotateY(180deg) scale(0.5, 0.5);
}

.member-photo .mask ul {
  text-align: center;
  position: relative;
  top: 125px;
}

.member-photo .mask ul li a {
  border: 1px solid #fff;
  color: #fff;
  display: block;
  font-size: 20px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
}

.member-photo .mask ul li a:hover {
  background-color: #fff;
  color: #2b6cd5;
  border: 1px solid transparent;
}

.member-meta {
  padding: 15px;
}

.member-meta h4 {
  margin: 0 0 5px;
}

.member-meta span {
  font-size: 12px;
}

/*=================================================================
  Testimonial section
==================================================================*/
.testimonial:before {
  background: rgba(42, 48, 55, 0.7);
}

.client-info {
  margin: 0 auto;
  width: 45%;
}

@media (max-width: 768px) {
  .client-info {
    width: 90%;
  }
}

.client-comment p {
  color: #d5dbe1;
  font-size: 18px;
}

.client-thumb img {
  border-radius: 50%;
  margin: 0 auto;
  max-width: 115px;
  width: 100%;
}

.client-meta {
  margin: 0 0 10px;
}

.client-meta h3 {
  margin: 15px 0 5px 0;
}

.client-meta span {
  font-size: 12px;
}

.owl-pagination,
.owl-buttons {
  text-align: center;
}

.owl-controls .owl-page {
  display: inline-block;
}

.owl-controls .owl-page span {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 50px;
  display: block;
  height: 13px;
  margin: 0 4px;
  width: 13px;
  opacity: 1;
  filter: alpha(opacity=100);
}

.owl-controls .owl-page.active span,
.owl-controls .owl-page:hover span {
  background-color: #2b6cd5;
  border: 2px solid transparent;
}

.our-clients {
  margin: 30px 0 0;
}

#our-clietns .item img {
  max-width: 150px;
  width: 100%;
}

#our-clietns .owl-next,
#our-clietns .owl-prev {
  border: 1px solid #ddd;
  border-radius: 50px;
  display: inline-block;
  margin: 45px 0 0;
  padding: 3px 15px;
  position: relative;
  text-align: center;
}

#our-clietns .owl-next i,
#our-clietns .owl-prev i {
  line-height: 34px;
}

#our-clietns .owl-prev {
  margin-right: 15px;
}

#our-clients .item {
  margin: 10px;
}

/*=================================================================
  Latest Posts
==================================================================*/
.note {
  background-color: #292f36;
  border: 1px solid #4e595f;
}

.post-block {
  background-color: #292f36;
  margin-bottom: 10px;
}

.post-block .content {
  padding: 20px;
}

.post-block .content h3 {
  margin: 0 0 6px;
  font-size: 20px;
  line-height: 1.5;
  text-transform: capitalize;
}

.note .media-wrapper {
  border-bottom: 1px solid #4e595f;
}

.note .excerpt h3 {
  font-size: 20px;
  line-height: 1.5;
  text-transform: capitalize;
}

.all-post {
  margin-top: 50px;
}

/*=================================================================
  Single Blog Page
==================================================================*/
#blog-banner {
  padding-bottom: 100px;
  padding-top: 150px;
}

#blog-page {
  margin: 0 0 40px;
}

.post-meta {
  font-size: 13px;
  margin: 20px 0 0;
  padding: 0 20px 10px;
}

.post-meta a {
  color: #2b6cd5;
}

.post-meta > span {
  color: #666;
  border-right: 1px solid #dedede;
  display: inline-block;
  font-size: 13px;
  margin: 10px 10px 0 0;
  padding-right: 10px;
}

.post-meta > span:last-child {
  border-right: 0;
}

.post-meta > span i {
  margin-right: 5px;
}

.post-excerpt {
  padding: 0 20px;
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt blockquote {
  line-height: 22px;
  margin: 20px 0;
  font-size: 16px;
}

.single-blog {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 20px;
}

.blog-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
}

.next-prev a {
  color: #000;
}

.next-prev a:hover {
  color: #2b6cd5;
}

.next-prev .prev-post i {
  margin-right: 10px;
}

.next-prev .next-post i {
  margin-left: 10px;
}

.social-profile ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}

.social-profile ul li a {
  color: #4e595f;
  display: block;
  font-size: 16px;
}

.social-profile ul li a i:hover {
  color: #2b6cd5;
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: #2b6cd5;
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input,
.comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus,
.comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid #2b6cd5;
}

.comment-reply-form textarea,
.comment-reply-form .btn-main {
  height: auto;
}

/*=================================================================
  Footer section
==================================================================*/
.footerArea a {
  color: #88b6ff;
}

.social-icon {
  padding: 60px 0 35px;
}

.social-icon ul {
  text-align: center;
}

.social-icon ul li {
  margin-bottom: 4px;
}

.social-icon ul li:hover a {
  background-color: #2b6cd5;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.social-icon ul li:hover a i {
  color: #fff;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.social-icon ul li a {
  background-color: #26292e;
  border: 1px solid transparent;
  display: block;
  height: 70px;
  width: 70px;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  transition: all 400ms ease-out 0s;
}

@media (max-width: 768px) {
  .social-icon ul li a {
    width: 55px;
    height: 55px;
  }
}

.social-icon ul li a i {
  color: #55606a;
  display: inline-block;
  font-size: 32px;
  line-height: 70px;
  margin: 0;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  transition: all 400ms ease-out 0s;
}

@media (max-width: 768px) {
  .social-icon ul li a i {
    line-height: 55px;
    font-size: 25px;
  }
}

.copyright {
  padding: 20px 0;
  color: rgb(0, 0, 0);
}

.copyright img {
  margin-bottom: 15px;
}

.copyright p {
  margin-top: 10px;
}

#scrollUp {
  border: 1px solid #4e595f;
  bottom: 15px;
  color: #fff;
  display: none;
  padding: 8px 12px;
  position: fixed;
  right: 20px;
  z-index: 600;
}

#scrollUp:hover {
  background-color: #2b6cd5;
  border: 1px solid #2b6cd5;
}

/*=================================================================
	Twitter Feed
==================================================================*/
#twitter-feed {
  /* background-image: url("../images/parallax/twitter.jpg"); */
  padding-bottom: 100px;
  padding-top: 80px;
}

.twitter-bird span {
  border: 2px solid #6cb670;
  border-radius: 50px;
  color: #6cb670;
  display: inline-block;
  margin-bottom: 50px;
  height: 90px;
  width: 90px;
}

.twitter-bird span i {
  line-height: 90px;
}

.tweet {
  margin-bottom: 10px;
}

.tweet a {
  color: #6cb670;
}

/*=================================================================
	Blog Page
==================================================================*/
#blog-banner {
  padding-bottom: 100px;
  padding-top: 150px;
}

.blog-icon {
  border: 1px solid #6cb670;
  border-radius: 50%;
  color: #6cb670;
  display: inline-block;
  padding: 16px 20px;
}

.blog-title h1 {
  text-transform: uppercase;
}

#blog-page {
  margin: 0 0 40px;
}

.post-item .entry {
  border: 1px solid #4e595f;
  margin-bottom: 30px;
}

.entry .post-excerpt {
  padding: 0 20px;
}

#gallery-post .owl-prev,
#post-thumb .owl-prev,
#gallery-post .owl-next,
#post-thumb .owl-next {
  padding: 5px 17px;
  position: absolute;
  top: 45%;
  transition: all 0.5s ease-in 0s;
}

#gallery-post .owl-prev,
#post-thumb .owl-prev {
  left: -45px;
}

#gallery-post .owl-next,
#post-thumb .owl-next {
  right: -45px;
}

#gallery-post:hover .owl-prev,
#post-thumb:hover .owl-prev {
  left: 0;
}

#gallery-post:hover .owl-next,
#post-thumb:hover .owl-next {
  right: 0;
}

#gallery-post,
#post-thumb {
  overflow-x: hidden;
}

#post-thumb .owl-buttons {
  color: #fff;
}

.post-meta {
  background-color: #3d444a;
  border-top: 1px solid #4e595f;
  font-size: 13px;
  margin: 20px 0 0;
  padding: 0 20px 10px;
}

.post-meta > span {
  border-right: 1px solid #4e595f;
  display: inline-block;
  font-size: 12px;
  margin: 10px 10px 0 0;
  padding-right: 10px;
}

.post-meta > span:last-child {
  border-right: 0;
}

.post-meta > span > i {
  margin-right: 5px;
}

.post-meta a:hover {
  color: #6cb670;
}

.post-excerpt .more {
  margin-top: 20px;
}

.post-excerpt .more a {
  display: inline-block;
  font-size: 13px;
  padding: 10px 25px;
}

.post-excerpt .more a:hover {
  background-color: #6cb670;
  color: #fff;
}

aside.widget {
  margin-bottom: 25px;
}

.widget-title > h3 {
  color: #fff;
  margin-bottom: 15px;
  margin-top: 0;
  position: relative;
}

#search-form {
  position: relative;
}

#search-submit {
  background: none;
  border: 0;
  color: #c8c8ca;
  display: block;
  font-size: 16px;
  height: 32px;
  outline: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 32px;
}

.widget-content .tab-content {
  background-color: #2f383d;
  padding: 20px;
}

.tab-post-nav {
  background-color: #4a535a;
}

.tab-post-nav li {
  float: left;
}

.tab-post-nav li.active a {
  border-top: 3px solid #6cb670;
  background-color: #2f383d;
  color: #6cb670;
}

.tab-pane.active .clearfix:first-child {
  margin-bottom: 25px;
}

.tab-excerpt > span {
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
}

.tab-excerpt h4 a {
  color: #fff;
}

.tab-excerpt h4 a:hover {
  color: #6cb670;
}

.tab-post-nav li a {
  border-top: 3px solid transparent;
  color: #fff;
  display: block;
  padding: 13px 18px;
  text-align: center;
}

.categories li {
  margin-bottom: 10px;
  transition: all 0.5s ease-in 0s;
}

.categories > li:hover a {
  padding-left: 10px;
  color: #6cb670;
}

.categories > li:last-child {
  border-bottom: 0;
}

.categories li a {
  padding-left: 5px;
}

.widget-content .tag {
  background-color: #2f383d;
  color: #fff;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 5px 13px;
}

.widget-content .tag:hover {
  background-color: #6cb670;
  color: #fff;
}

.post-pagination {
  display: inline-block;
  margin-bottom: 20px;
}

.post-pagination ul li {
  margin-right: 5px;
  float: left;
}

.post-pagination ul li:last-child {
  margin-right: 0;
}

.post-pagination ul li.disabled a {
  cursor: not-allowed;
}

.post-pagination ul li.disabled a:hover {
  background-color: #2e373c;
  color: #9aa8b3;
}

.post-pagination ul li a {
  background-color: #30393e;
  color: #9aa8b3;
  display: block;
  padding: 8px 18px;
}

.post-pagination ul li a:hover,
.post-pagination ul li.active a {
  background-color: #6cb670;
  color: #fff;
}

/*=================================================================
	Single Blog Page
==================================================================*/
.single-blog {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 20px;
}

blockquote {
  border-left: 5px solid #6cb670;
  font-style: italic;
  line-height: 22px;
  margin: 20px 0;
}

.next-prev {
  border-bottom: 1px solid #4e595f;
  border-top: 1px solid #4e595f;
  margin: 20px 0;
  padding: 15px 0 10px;
}

.next-prev a:hover {
  color: #6cb670;
}

.prev-post i {
  float: left;
  margin-right: 10px;
  margin-top: -4px;
}

.next-post i {
  float: right;
  margin-left: 10px;
  margin-top: -3px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #4e595f;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-reply-form > h3,
.author-about > h4,
.comments > h4 {
  border-bottom: 1px solid #4e595f;
  margin-bottom: 15px;
  margin-top: 0;
  padding-bottom: 10px;
  position: relative;
}

.author-bio h5 {
  display: inline-block;
}

.post-item .comments-section {
  margin-top: 35px;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #4e595f;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-left: -25px;
  margin-right: 20px;
}

.author-comment {
  margin-bottom: 15px;
  overflow: hidden;
}

.author-comment cite a:hover,
.replay:hover {
  color: #6cb670;
  text-decoration: underline;
}

.author-comment cite {
  margin-bottom: 2px;
  padding-top: 4px;
}

.comment-content {
  margin-left: 45px;
}

.comment-list ul li {
  padding-left: 25px;
}

.comment-reply-form {
  margin-top: 80px;
}

#comment-form {
  margin-top: 35px;
}

#comment-form .form-control:hover {
  border-color: #6cb670;
}

.form-group:last-child,
.form-group:last-child input {
  margin: 0;
}
/* Table Styles: */

table.GeneratedTable {
  width: 100%;
  /* background-color: rgba(255, 255, 255, .9); */
  /* border-collapse: collapse;
  border-width: 2px;
  border-color: #1d1d1d28;
  border-style: solid;
  color: #000000; */
  border-bottom: #fff solid 3px;
  color: #ffffff;
}

table.GeneratedTable td,
table.GeneratedTable th {
  /* border-width: 1px;
  border-color: #1d1d1d28;
  border-style: solid; */
  border-bottom: #ffffff2e solid 3px;
  padding: 10px;
  text-align: center;
}

table.GeneratedTable thead {
  background-color: #274472;
  text-align: center;
  text-align: center;
  color: #fff;
}

/* -------------------------------------------- */
/*               Custom Scroll Bar              */
/* -------------------------------------------- */

/* width */
::-webkit-scrollbar {
  width: 5px;
  /* padding: 2px; */
    background-image: linear-gradient(to right, #274472, #001930);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2b6cd5;
  border-radius: 10px;
}

/* Slideshow */
#slideshow {
  /* margin: 50px auto;  */
  position: relative;
  /* width: 100%;  */
  max-width: 100%;
  height: 100vh;
  /* padding: 10px;  */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  margin-top: -100px;
}
@media (max-width: 1024px) {
  #slideshow {
    display: none;
  }
}
/* .slideshow:before {
  background: red;
  position: absolute; 
  top: 0px; 
  left: 0px; 
  right: 0px; 
  bottom: 0px; 
} */

#slideshow > div {
  position: absolute;
  /* background: red; */
  height: 100vh;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  -webkit-animation: zoomin 30s ease-in infinite;
  animation: zoomin 30s ease-in infinite;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
/* #slideshow > div:before{
  position: absolute; 
  top: 0px; 
  left: 0px; 
  right: 0px; 
  bottom: 0px; 
  background: red;
} */

/* #slideshow > div:before { 
  position: absolute; 
  top: 0px; 
  left: 0px; 
  right: 0px; 
  bottom: 0px; 
  -webkit-animation: zoomin 30s ease-in infinite;
  animation: zoomin 30s ease-in infinite;
  transition: all .5s ease-in-out;
  overflow: hidden;
} */
/*# sourceMappingURL=maps/style.css.map */
.newButton {
    background-image: linear-gradient(to right, #274472, #274472);
  color: #fff;
  padding: 10px;
  border: none;
}
.newButton-light {
  background: #2b6cd5e3;
  color: #fff;
  padding: 10px;
  border: none;
}
/* Services accordian */
/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-image: linear-gradient(to right, #274472, #001930);
  /* color: #444; */
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.collapsible:hover {
  background-color: rgb(4, 47, 88);
}

/* Style the collapsible content. Note: hidden by default */
.collapaibleContent {
  /* padding: 0 18px; */
  /* display: none; */
  overflow: hidden;
  /* background-color: #f1f1f1; */
  padding-top: 25px;
  max-height: 0;
  overflow: hidden;

  transition: max-height 0.5s ease-out;
  width: 100%;
}


.collapaibleContentOpen
{
    overflow: visible;
    padding-top: 20px;

    padding-bottom: 20px;

    max-height:fit-content;
    transition: max-height 0.2s ease-in-out;
}
/* Dropdown Menu */
li a:hover,
.dropdown:hover .dropbtn {
  background-color: rgb(4, 47, 88);
  color: #fff;
}

li.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
li a,
.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: rgb(4, 47, 88);
  color: #fff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .emergency {
    width: 100%;
    height: 100vh;
    padding: 80px 20px 20px 20px;
  }
  .emergencyButton {
    display: inline-block;
    border: 1.5px solid #f8f8f8;
    background-color: transparent;
    color: #fff;
    padding: 10px 30px;
    border-radius: 0;
    transition: all 0.3s ease-in 0s;
  }
  .hideOnDesktop {
    display: block;
  }

  .mobileSlides {
    height: 500px;
    padding: 20px;
    background: #00192f;
  }

  /* .hero-area {
      background-image: url(../images/slider/hero-area2.jpg);
    } */
  .mobileTime {
    /* background: red; */
    /* background-image: linear-gradient(rgba(1, 11, 21, 0.68), rgb(0, 25, 47)),
      url(../images/slider/hero-area2.jpg); */
    /* background: #27447230; */
    text-align: center;
  }
  /* .mobileTime::before {
      background: #27447230;
    } */
}
.sideBar {
  width: 100%;
  background: #012445;
  height: 650px;
  padding: 25px;
  text-align: center;

  /* box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 3px; */
  /* border: rgba(0, 0, 0, 0.265) solid thin */
}
.sideBarLight {
  width: 100%;
  background: #f3f3f3;
  height: 650px;
  padding: 25px;
  text-align: center;

  /* box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 3px; */
  /* border: rgba(0, 0, 0, 0.265) solid thin */
}
.services-font {
  font-size: 20px;
}
.serviceColumn {
  padding-left: 120px;
  margin-left: 50px;
}
.sideBarPadding {
  padding-right: 120px;
}
homeButton {
}
.mobileHomeButton {
  display: none;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .serviceColumn {
    padding: 20px;
    margin-left: 0px;
  }
  .sideBarPadding {
    padding-right: 0px;
    padding: 5px;
  }
  .mobileImage {
    width: 100%;
  }
  .mobileHomeButton {
    background: #fff;
    font-weight: bold;
    color: #115f9e;
    /* font-size: 5px; */
    font-family: Quattrocento Sans, sans-serif;
    box-shadow: 0px 3px 9px 1px #88888840;
    text-align: right;
    align-items: right;
    /* margin-left: 250px; */
    /* display: inline; */
    position: relative;
    float: right;
    display: block;
  }

  .title {
    padding-bottom: 20px;
  }
}

#menuItem {
  display: block;
  color: #2b6cd5;

}
#menuItemMobile {
  display: none;
}
@media screen and (max-width: 480px) {
  #menuItemMobile {
    display: block;
  }
  #menuItem {
    display: none;
  }
}

@media screen and (min-width: 480px) {
  .about .block {
    height: 500px;
  }
}

.textHighlight {
  font-size: 25px;
  color: #54c5c6;
  font-weight: bold;
}
.footer {
  background-image: linear-gradient(to right, #274472, #001930);

}

.img-responsive {
    background-size: contain;
}


.bg-dark h2, .bg-dark h4, .bg-one h3 {
    color: white;
}
.bg-dark p, .bg-one p {
    color: rgba(255, 255, 255, 0.648);

}

.service-block h3, .service-block p  {
    color: #00192f;
}

.footer a {
    text-decoration: none;
}

.about-2 a {
    text-decoration: none;
    color: #88b6ff;

}