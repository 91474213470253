body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: #F5EFE7;
}

.login-container {
  display: flex;
  height: 100vh;
}

.login-wrapper {
  width: 100%;
}

.login-logo {
  display: none;
}

.row-custom {
  display: flex;
  height: 100vh;
}

.column {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
}

.column-small {
  flex: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  background: #F5EFE7;
  transition: flex 0.5s ease-out;
  color: #fff;
}

.column-big {
  flex: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  background: #4F709C;
  color: #F5EFE7;
  transition: flex 0.5s ease-out;
  background-image: linear-gradient(to right, #274472e8, #001930df);
}

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 70px 0;
  height: 100%;
  width: 100%;
}

.auth-container h3 {
  margin-bottom: 20px;
  text-align: left;
  margin-left: 430px;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-form-label {
  font-size: 25px;
  margin-bottom: 15px;
  margin-left: 10px;
}

.login-form, .signup-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.login-form input, .signup-form input, .login-form button, .signup-form button, .signup-form select {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #D8C4B6;
}
.form-button {
  width: 100%;
  background-image: linear-gradient(to bottom, #134897, #2b6cd5);
  /* background: #213555; */
  color: #F5EFE7;
  cursor: pointer;
  border: none !important;
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
}

.form-button-alt {
  width: 100%;
  background-image: linear-gradient(to bottom, #01203d,  rgba(0,25,48, 1));
  /* background: #213555; */
  color: #F5EFE7;
  cursor: pointer;
  border: none !important;
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
}

.form-button:hover {
  background: #004dd3;
}

.form-button-alt:hover {
  background: #02235b;
}


.form-button:active {
  background: #15233F;
}

.column h1, .column h2 {
  text-align: center;
  color: #213555;
}

@media screen and (max-width: 768px) {
  .row-custom {
    flex-direction: column;
  }

  .column, .column-small, .column-big {
    flex: 100%;
  }
  .login-padding-top {
    padding-top: 15vh;
  }


  .login-form, .signup-form {
    max-width: 100%;
  }

  .login-form input, .signup-form input, .login-form button, .signup-form button, .signup-form select {
    font-size: 16px;
  }

  .form-button {
    font-size: 18px;
  }
}

.verification-modal .modal-content {
  background-color: #fff;
  color: #213555;
}

.verification-modal .modal-header, .verification-modal .modal-body {
  background-color: #fff;
  color: #213555;
}

.verification-modal .resend-code-btn {
  color: #4f709c;
  text-decoration: underline;
  padding: 0;
}

.alert-style {
  top: 20px;
  right: 20px;
  z-index: 9999;
}

.error-message {
  color: #f25656;
}

.error-button {
  background-color: #213555;
}
.screen-container {
  display: flex;
  padding-top: 60px;
}

.screen-title {
  font-weight: bolder;
}

.screen-back {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.screen-back:hover {
  color: #4F709C;
  text-decoration: underline;
}

.screen-title-container {
  margin-left: 25px;
  margin-bottom: 40px;
}

.screen-content-container {
  margin-left: 25px;
}

@media (max-width: 900px) {
  .screen-content-container {
    margin-left: 0px;
  }
}

.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 60px;
  width: 20%;
  height: 100vh;
  overflow-y: hidden;
  background-image: linear-gradient(120deg, #104fb6,#001930);
  color: white;
  z-index: 999;
}

.content-container {
  width: 70%;
  background-color: white;
  margin-left: 20%;
}

@media screen and (max-width: 1300px) {
  .content-container {
    width: 100%;
    margin-left: 0;
    padding: 20px;
  }

  .screen-title-container {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 0px;
  }
}

.nav-container button {
  margin: 1em;
  background-color: white;
  color: navy;
  border: none;
  padding: 0.5em 1em;
  cursor: pointer;
}

.nav-container button:hover {
  background-color: lightblue;
  transition: background-color 0.3s ease;
}

.navbar {
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
}
.full-height {
  height: 100%;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
}

.navbar li:last-child {
  border-bottom: none;
}

.nav-link {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 14px 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;
}

.nav-item {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 12px 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;
}

.nav-item:hover {
  color: rgb(255, 255, 255);
  text-decoration: underline;
  transition: background-color 0.3s ease;
}

.nav-link-bottom {
  margin-top: auto;
  margin-bottom: 30px;
}

.nav-link:hover {
  color: black;
  border-bottom: 1px solid white;
  transition: background-color 0.3s ease;
}

.custom-divider {
  height: 1px;
  background-color: white;
  width: 100%;
}

.navIcon {
  margin-right: 10px;
}

.logo-container {
  padding-bottom: 60px;
}

.navText {
  font-weight: bolder;
  text-decoration: none;
  font-size: 16px;
}

.secondaryHeader {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px;
  color: #213555;
  font-weight: 600;
}

.landing-text-container {
  margin-bottom: 20px;
  /* margin-left: 25px; */
  font-size: 25px;
  color: #666666;
}

.button-primary {
  margin-top: 20px;
  background-color: #063561 !important;
  outline: none !important;
}
.button-secondary {
  /* background-color: #1c5cb4  !important; */
  background-image: linear-gradient(120deg,#1c5cb4, #023e77) !important ;
  color: #fff !important;
  outline: none !important;
  font-weight: 600 !important;

  /* border-color: #003366 !important;
  border-width: 2px !important; */

}
.button-secondary:hover {
  background-image: linear-gradient(180deg,#04396b,#032545) !important;
  color: #fff !important;

}
.button-outline {
  background-color: transparent !important;
  color: #0759a7 !important;
  border-width: 2px !important;
  border-color: #0759a7 !important;
  font-weight:700 !important;
}

.button-outline:hover {
  background-image: linear-gradient(180deg,#04396b,#032545) !important;
  color: #fff !important;
  border: none !important;

  font-weight:700 !important;
}

.button-outline:focus {
  background-image: linear-gradient(180deg,#04396b,#032545) !important;
  color: #fff !important;
  border: none !important;

  font-weight:700 !important;
}

.time-period-button{
  margin-right: 10px;
  margin-bottom: 10px;
}
.pdf-header {
  color: #003bb0;
  font-weight: 700;
  font-size: 1.6em;
}
.pdf-header-alt {
  color: #434343;

  font-weight: 700;
  font-size: 1.6em;
  text-transform: capitalize;
}
.pdf-subtitle{
  color: #003bb0;
  font-weight: 700;
} 
.payroll-report-logo {
  width: 150px;  
  height: auto;
  margin-bottom: 10px;
  float:inline-end;
}
.payroll-report-button {
  font-size: 1.2em !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-top: 20px;
  display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 8px !important; 
    text-decoration: none !important;
}

.button-link-wrapper {
  text-decoration: none;

}

@media (max-width: 900px){
  .payroll-report-button {width: 100% !important;}
}

.button-primary:hover {
  margin-top: 20px;
  background-color: #01519c !important;
  outline: none !important;
}
.create-company-button {
  position: fixed;
  top: 30px;
  right: 100px;
  z-index: 999;
  color: #ffffff;
  font-size: 20px !important;
}

@media (max-width: 1300px) {
  .create-company-button {
    position: static;
    top: auto;
    right: auto;
    margin-bottom: 10px;
  }
}

.create-company-button:hover {
  background-color: #213555 !important;
  color: #F5EFE7;
}

.custom-close-button {
  color: #fff;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}
.accordianTitleContainer {
  margin-left: 25px;
  font-size: 25px;
  color: #666666;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #05142d; */
  background-image: linear-gradient( #274472, #001930);  color: rgb(255, 255, 255);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.timer-header {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.885);
}

.timer-display {
  font-size: 40px;
  font-weight: bold;
}

.time-stamp-button {
  width: 400px;
  background-image: linear-gradient(to bottom, #133c79, #2b6cd5);
  color: #F5EFE7;
  cursor: pointer;
  border: none !important;
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
}

@media (max-width: 800px) {
  .time-stamp-button {
    width: 100%;

  }
}

.time-stamp-button:hover {
  background-image: linear-gradient(to bottom, #0d55c2, #2b6cd5);

}

.btn-primary {
  background-color: #003bb0;
  border-color: #1461b3;
}

.download-button {
  margin: 25px;
  background-image: linear-gradient(180deg,#032545,#001930) !important;
  color: #F5EFE7;
  font-weight: bold;
  outline: none !important;
}
.static-landing-page-root {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
}

.static-landing-page-navbar {
  background-image: linear-gradient(to right, #2b6cd5, #001930);
  padding: 20px 0;
  box-shadow: 0px 3px 9px 1px #88888840;
}

.static-landing-page-navbar .nav-link {
  color: #d7d7d7c9;
  transition: color 0.3s;
  margin-right: 20px;
}

.static-landing-page-navbar .navbar-toggler-icon {
  background-color: white;
  filter: invert(100%);
}

.static-landing-page-navbar .nav-item:last-child {
  margin-right: 0;
}

.static-landing-page-navbar .nav-link:hover {
  color: #ffffff;
}

.static-landing-page-navbar .nav-link.active-nav-link {
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
}
@media (max-width: 900px) {
  .static-landing-page-navbar.expanded .navbar-collapse {
      height: 100vh;
      overflow-y: auto;
  }
}
.active-nav-link {
  color: #ffffff !important;
  font-weight:bold;
}

.static-landing-page-logo {
  color: white !important;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Starts fully transparent */
  }
  to {
    opacity: 1; /* Ends fully visible */
  }
}

.static-landing-page-hero {
  /* animation: fadeIn .5s forwards;  */
}

.static-landing-page-hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.static-landing-page-section {
  padding: 80px 0;
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.static-landing-page-about, .static-landing-page-contact {
  background-color: #ffffff;
  color: #15233F;
}

.static-landing-page-services {
  background-color: #213555;
}
.static-landing-page-footer {
  background-color: #15233F;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.static-landing-page-portal-button {
  margin-left: 10px;
  background-image: linear-gradient(to bottom, #133c79, #2b6cd5) !important;
    border-color: #79abfbb4 !important;
  font-size: 20px !important;
  padding: 15px;
  border-radius: 10px;
}

.static-landing-page-portal-button:hover, .static-landing-page-portal-button:focus {
  background-image: linear-gradient(to bottom, #2b6cd5, #2b6cd5) !important;
  border-color: #79abfb !important;
    box-shadow: none;
}


.btn-outline-light {
  border-color: #fff;
  color: #fff;
}

.btn-outline-light:hover {
  background-color: #fff;
  color: #15233F;
}

@media (max-width: 1300px) {
  .nav-container {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .nav-container-show {
    transform: translateY(0);
  }
}

.toggle-nav-button {
  display: none;
  position: fixed;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #213555;
  cursor: pointer;
  z-index: 999;
}

@media (max-width: 1300px) {
  .toggle-nav-button {
    display: block;
  }
}

.nav-container {
  display: block;
}

@media screen and (max-width: 1300px) {
  .nav-container-hide {
    display: none;
  }

  .nav-container-show {
    display: block;
  }
}

.top-menu {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 10px 3em;
  background-image: linear-gradient(120deg,#2b6cd5, #023e77,#001930);
  color: #F5EFE7;
  z-index: 1000;
  position: fixed;
  width: 100%;
  top: 0;
}

.top-menu svg {
  cursor: pointer;
}

.mobile-nav-close {
  display: flex;
  justify-content: flex-end;
  padding-right: 3em;
  color: #7898c1;
}

@media (max-width: 1300px) {
  .top-menu {
    display: flex;
  }

  .nav-container {
    padding-top: 20px;
  }
}

.nav-container {
  display: block;
}

@media screen and (max-width: 1300px) {
  .nav-container-hide {
    display: none;
  }

  .nav-container-show {
    display: block;
  }
}

.navbar {
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
}

.full-height {
  height: 100%;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
}

.navbar li:last-child {
  border-bottom: none;
}

.nav-link {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 14px 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;
}

.nav-item {
  display: block;
  color: rgba(255, 255, 255, 0.65);
  text-align: center;
  padding: 12px 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 18px;
}

.active-nav-item {
  color: rgb(255, 255, 255);
  /* text-decoration:underline; */
  /* background-color: rgba(0, 0, 0, 0.1);  */
}

@media (max-width: 900px) {
  .static-landing-page-navbar.expanded .navbar-nav {
    /* background-color: #01203d; */
    margin-top: 10px;
    margin-bottom: 10px;

  }

  .static-landing-page-navbar.expanded .nav-item {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .static-landing-page-navbar.expanded .nav-item:not(:last-child) {
      border-bottom: 2px solid #b2b3b428; /* This color is a typical Bootstrap border color; adjust if necessary. */
      margin-right: 0; /* If there's any right margin, you can remove it to make the divider span the entire width. */
  }
  .static-landing-page-navbar.expanded .nav-item {
    
    border-bottom: 2px solid #b4b2b228; /* This will add a divider after every nav item. */
}

.static-landing-page-navbar.expanded .nav-item:first-child {

    border-top: 2px solid #b4b2b228; /* This will add a divider before the first nav item. */
}
.static-landing-page-navbar.expanded .static-landing-page-portal-button {
  background-image: linear-gradient(to bottom, #01203d,  rgba(0,25,48, 1)) !important;

 display: block;
 margin: 0 auto; 
 margin-top: 30px;


}
}


.nav-item:hover {
  color: rgb(255, 255, 255);
  text-decoration: underline;
  background-color: transparent;
  /* transition: background-color 0.3s ease; */
}




/* .nav-item:active {
  color: #b00084;
} */

.nav-link-bottom {
  margin-top: auto;
  margin-bottom: 30px;
}

.nav-link:hover {
  color: black;
  border-bottom: 1px solid white;
  transition: background-color 0.3s ease;
}

.custom-divider {
  height: 1px;
  background-color: white;
  width: 100%;
}

.navIcon {
  margin-right: 10px;
}

.logo-container {
  padding-bottom: 60px;
}

.navText {
  font-weight: bolder;
  text-decoration: none;
  font-size: 16px;
}

/* about page */

.static-about-section {
  background-color: #ffffff;
  padding: 50px 0;
}

.static-about-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.static-about-divider {
  width: 100px;
  height: 2px;
  background-color: #333;
  margin: 10px auto 40px auto;
}

.static-about-card {
  background-color: #246b9c;
  color: #fff;
  border-radius: 10px;
  border: none;
  height: 100%;
  transition: all 0.3s;
}

.static-about-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.static-about-icon-box {
  margin-bottom: 20px;
  display: inline-block;
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
}

/* Card Styling */
.static-pageCard {
  border: none;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
  background: #274472; /* Primary color */
}

.static-pageCard:hover {
  transform: scale(1.05);
}

.static-pageCardHeader {
  background-color: #001930; /* Secondary color */
  font-size: 24px;
  font-weight: 600;
  color: #fff; /* Text color adjustment */
}

.static-pageCardFooter {
  font-weight: 600;
  background-color: #001930; /* Secondary color */
  color: #fff; /* Text color adjustment */
}

.static-pageList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.static-pagePrice {
  font-size: 22px;
  font-weight: 600;
}




/* Style for the left column */
.static-page-left {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* Style for the right column */
.static-page-right {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start; 
  /* background-color: #001930ad; */
    /* padding-left: 30%; */
    padding-top: 10%;
    padding: 200px;



}

.static-page-right h1 {
  font-size: 48px; /* Adjust the font size as needed */
  font-weight: bold; /* You can customize the font-weight */
  margin-bottom: 10px; /* Adjust the margin as needed */
  text-align: left;
  margin-left: 5%;


}

/* Style for h3 inside static-page-right */
.static-page-right h3 {
  font-size: 26px; /* Adjust the font size as needed */
  margin-top: 0; /* Remove default top margin */
  margin-bottom: 20px; /* Adjust the margin as needed */
  text-align: left;
  margin-left: 5%;
}

.static-page-right Button {
  font-size: 18px; /* Adjust the font size as needed */
  margin-top: 0; /* Remove default top margin */
  text-align: center;
  margin-left: 5%;
  padding-left: 20px;
  padding-right: 20px;
  width: 30%;

}




.static-hero-area-text-container h1 {
  font-size: 2.5em;
  font-weight: 600;
  color: #fffffff2;

}
.static-hero-area-text-container h3 {
  font-size: 1.5em;
  margin-right: 10px;
  font-weight:100;
  color: #ffffffaf;
  /* line-height: 1.3;  */



}

.static-hero-area-text-container Button, .static-hero-area-text-container a {
  width: 80%;
  background-image: linear-gradient(to bottom, #133c79, #2b6cd5);
  color: #F5EFE7;
  cursor: pointer;
  border: none;
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
}

.static-hero-area-text-container Button:hover, .static-hero-area-text-container a:hover {
  background-image: linear-gradient(to bottom, #2b6cd5, #2b6cd5);

}
/* Slide-in animation */
@keyframes slideIn {
  to {
    left: 0%;
  }
}

@media (max-width: 900px) {

  .static-hero-area-text-container h1 {
    font-size: 2.2em;
    font-weight: 600;
  
  }
  .static-hero-area-text-container h3 {
    font-size: 1.2em;
    margin-right: 10px;
    font-weight:100;
  
  }
}

/* alt container */

  
.static-hero-area-text-container-alt {
  text-align: left;
  position: absolute;
  top: 25%;
  /* right: -70%;  */
  transform: translateY(-20%);
  background-color: #fffffff6;
  padding: 20px;
  animation: slideInAlt 1s forwards 0.5s; /* Apply the slide-in animation */
  width: 40%;
  height: auto; 
  padding: 50px;
}



.static-hero-area-text-container-alt h1 {
  font-size: 2.5em;
  font-weight: 600;
  color: #001930;

}
.static-hero-area-text-container-alt h3 {
  font-size: 1.5em;
  margin-right: 10px;
  font-weight:lighter;
  color: #001930;


}

.static-hero-area-text-container-alt Button, .static-hero-area-text-container-alt a {
  width: 80%;
  background-image: linear-gradient(to bottom, #133c79, #2b6cd5);
  color: #F5EFE7;
  cursor: pointer;
  border: none;
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
}

.static-hero-area-text-container-alt Button:hover, .static-hero-area-text-container-alt a:hover {
  background-image: linear-gradient(to bottom, #2b6cd5, #2b6cd5);

}
/* Slide-in animation */
@keyframes slideInAlt {
  to {
    right: 0%;
  }
}

@media (max-width: 900px) {
  .static-hero-area-text-container-alt {
    background-color: #ffffffd2;
    top: 20%;
    left: 0;
    right: 0;
    width: 100%; /* Full width */
    height: auto; /* You can adjust this or let content determine height */
    padding: 20px; /* Adjust padding if needed for smaller screens */
    border-radius: 0; /* Optional: remove border radius for full width */
    padding-bottom: 30px;

  }
  .static-hero-area-text-container-alt h1 {
    font-size: 2.2em;
    font-weight: 600;
  
  }
  .static-hero-area-text-container-alt h3 {
    font-size: 1.2em;
    margin-right: 10px;
    font-weight: lighter;
  
  }

}

/* feature section */
.static-feature-section {
  background-color: #ffffff;
  padding-top: 50px;
  /* padding-bottom: 100px; */

  display: flex;
  justify-content: center;
  align-items: center; /* To vertically center content */
  /* height: 100vh; */
}

@media (max-width: 900px) {
  .static-feature-section {
    display: block;
    height: 100%;
  }
}

.static-feature-section h1 {
  color: #133c79;
  margin-bottom: 60px;
  font-size: 3em;
  font-weight: 700;
  /* font-variant: small-caps; */
  font-style: italic;
  position: relative;
  padding-bottom: 15px;
  text-align: center; 


&::first-letter {
  color: #133c79;
  font-size: 1.6em;

}
  /* Curvy and fading underline effect */
  &::after {
    content: "";
    display: block;
    width: 30%;
    height: 4px;
    background: linear-gradient(90deg, #133c79, #0a58ca);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    border-radius: 50%/100%; /* This makes it curvy */
  }
}
.static-feature-section span {
  color: #014dbe;
}


.feature-card {
  display: flex !important;
  height: 650px !important;
  justify-content: center !important;
  align-items: center !important; 
  text-align: center;
  background-image: linear-gradient( #274472, #001930);
  padding: 30px;
  border-bottom: 2px solid transparent;
  margin-bottom: 20px;
  border-radius: 15px; 
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden; 
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 20px; 
  /* color: #F5EFE7 !important;  */
}

.feature-card:hover {
  transform: translateY(-5px);
  background-image: linear-gradient( #0b4db7, #001930);

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

.feature-card .card-body {
  color: #e3e3e3d2 !important; /* Light color for general text readability against the gradient */
  font-weight:lighter !important;

}

.feature-card .card-title {
  font-size: 1.5em !important;
  color: #f2f2f2e9 !important; /* Slightly brighter color for the title to make it pop */
  font-weight: 600 !important;
  margin-top: 20px !important;

  margin-bottom: 20px !important;
}

.feature-card a, .feature-card button {
  width: 80%;
  background-image: linear-gradient(to bottom, #133c79, #2b6cd5);
  color: #F5EFE7;
  cursor: pointer;
  border: none;
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
}

.feature-card a:hover, .feature-card button:hover {
  background-image: linear-gradient(to bottom, #0d55c2, #2b6cd5);
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
  opacity: 1;
  transition: opacity 1s ease-out;
}

.loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #003366; 
  font-size: 2rem;
  font-weight: bold;
  z-index: 1000; /* Ensure the text is above the waves */
  /* animation: pulse 1s infinite; */


}

.waves {
  position: absolute;
  bottom: 0;
  width: 200%; /* 200% to allow animation space */
  height: auto;
  transform: translateX(0);
  animation: moveWave 10s linear infinite;
  /* transform: scale(1, 1.5) translateX(0); */

}


@media (max-width: 800px) {
  .waves {
    width: auto;
    height: 40vh;


  }
}


.fade-out {
  opacity: 0;
}

@keyframes moveWave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Moves it by half its width */
  }
}
.spinner {
  display: none; /* hiding the spinner to focus on the wave animation */
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.service-plan-cards-container {
  width: 100%;
  /* padding: 20px; */
  text-align: center;
  display: flex;
  justify-content:stretch;
  flex-wrap: wrap;
}

.service-plan-cards-card {
  width: 30%; /* Adjust this value based on the desired width of the cards */
  /* border: 1px solid rgba(204, 204, 204, 0.692); */
  border-radius: 10px;
  margin: 15px 1%; /* Adjust the second value (horizontal margin) to maintain the spacing between the cards */
  box-shadow: 8px 8px 8px rgba(0,0,0,0.3);
  overflow: hidden;
  height: 450px;
  background-color: #E7F0F1;
  transition: transform 0.3s ease; /* smooth transition for the hover effect */

}

.service-plan-cards-card:hover {
  transform: translateY(-20px); /* This moves the card up by 10 pixels on hover */
  box-shadow: 10px 10px 10px rgba(0,0,0,0.3); /* You can adjust the shadow to give a lifted effect */
}

.service-plan-cards-card-header {
  /* background-color: #003366; */
  background-image: linear-gradient(to bottom, #0d459e,#003366);
  color: white;
  font-weight: 700;
  padding: 15px 20px;
  font-size: 30px;
}


.service-plan-cards-card-button, .service-plan-cards-card-button-price {
  margin-top: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.service-plan-cards-card-button {
  width: 80% !important;
    background-color: #003366 !important;
  /* background-image: linear-gradient(to bottom, #133c79, #2b6cd5); */
  color: #F5EFE7 !important;
  cursor: pointer !important;
  border: none !important;
  font-size: 20px !important;
  padding: 10px !important;
  border-radius: 10px !important;
  margin-top: 15px !important;
}
.service-plan-cards-card-button:hover {
  background-color: #0150a0 !important;

     
}

  .service-plan-cards-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    height: calc(100% - 45px); /* Assuming 50px is the height of your header, adjust if different */
    padding: 15px 0px; /* Your original padding */
  }
  
  .service-plan-cards-card-body ul {
    text-align: left;
    list-style:square;
    color: #3e4955;
    font-weight: 600;
    margin-top: 10px;
    /* margin-left: 25px; */

/* text-align: center; */
   
  }
  
  .service-plan-cards-card-body .service-plan-cards-card-button {
    margin-top: auto; /* Push the access info to the bottom */
    font-weight: 700;
  align-self: center;
       
  }

 
  .service-plan-cards-card-access-info, .service-plan-cards-card-no-access-info {
    margin-top: auto; /* Push the access info to the bottom */
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 700;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    color: #fff;

  }

.service-plan-cards-card-button-price {
  background-color: #40835d !important;  /* Bootstrap's "success" color */
  font-weight: 700;
}

.service-plan-cards-card-button-price:hover {
  background-color: #0a9747 !important;  /* Bootstrap's "success" color */
}



.service-plan-cards-card-access-info{
  background-color: #40835d;
}

.service-plan-cards-card-no-access-info {
  background-color: #ae3b3b;
}

@media (max-width: 992px) { /* Adjust for smaller screens */
  .service-plan-cards-card {
      width: 45%;
      margin: 15px 2.5%;
  }
}

@media (max-width: 768px) { /* Adjust for even smaller screens */
  .service-plan-cards-card {
      width: 100%;
      margin: 15px 0;
  }
}
.access-denied-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
}

.access-denied-content {
  text-align: center;
  max-width: 700px;
  padding: 20px;
  padding-bottom: 50px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.access-denied-content h1 {
  margin-bottom: 16px;
  font-weight: bold;
  color: #003366;
  font-size: 24px;
}

.access-denied-content p {
  margin-bottom: 24px;
  color: #6c757d;
  font-weight: 700;

}

.btn-back-portal {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-back-portal:hover {
  background-color: #0056b3;
}

/* ViewDocs.css */
.spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.document-list-container {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f7f7f7; /* Light grey background */
}

.document-item {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #ffffff; /* White background for each item */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.document-actions-container .document-start-container {
  display: flex;
  align-items: center;
  /* If you want some space between the date and download button */
  gap: 20px; 
}

.file-icon {
  margin-right: 10px;
  color: #326697; /* Blue color for the file icon */
}

.file-name {
  justify-content:flex-start;
  color: #003366; /* Dark text for better readability */
  font-weight: 600;
}

.no-documents-message {
  text-align: center;
  margin-top: 20px;
  color: #888; /* Grey text when no documents */
}
.document-inner-container {
  display: flex; /* This will align the spans next to each other */
  justify-content: space-between; /* This will put maximum space between the two spans */
  align-items: center; /* This will vertically center the spans if they're of different heights */
  width: 100%; /* This ensures the container takes up full width of its parent */
}
.file-date {
  color: #6c757d; /* Lighter text for the date */
  font-size: 0.9em;
  margin-right: 20px;
}

.file-download-link {
  color: #003bb0;
  font-weight: 600;
}

@media (max-width: 900px) {
  .document-item {
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center-align flex items */
  }
  
  .document-start-container,
  .document-actions-container {
    justify-content: center; /* Center content within these containers */
    width: 100%; /* Take full width to center content correctly */
  }
  
  .file-date {
    margin: 10px 0; /* Add margin above and below the date */
    padding-right: 20px;
  }
  
  .file-download-link {
    white-space: nowrap; /* Prevent the download text from wrapping */
  }
}

.upload-controls {
  margin-top: 20px; /* Give some space from the top */
}

.primary-button, .upload-button {
  background-image: linear-gradient(to bottom, #1750a5, #154084);
  color: #F5EFE7;
  cursor: pointer;
  font-weight: 700;

  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-button:hover, .upload-button:hover {
  background-image: linear-gradient(to bottom, #2568cd, #2565cb);
}

.file-input {
  margin-top: 10px; /* Space from the 'Upload Document' button */
}